import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appHideOnKeyboard]',
  standalone: true,
})
export class HideOnKeyboardDirective {
  @HostBinding('style.display')
  protected cssDisplay = '';

  @HostListener('window:message', ['$event'])
  async onWindowMessage(msg: MessageEvent) {
    const { data } = msg;
    if (data.from !== 'mobappV1') {
      return;
    }
    if (data.cmd === 'eventKeyboardWillShow') {
      this.cssDisplay = 'none';
    }
    if (data.cmd === 'eventKeyboardWillHide') {
      this.cssDisplay = '';
    }
  }
}
